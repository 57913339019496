$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower


@font-face {
  font-family: 'jackregular';
  src: url("../fonts/jack-regular-webfont.woff2") format("woff2"), url("../fonts/jack-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Yantramanav';
  src: url("../fonts/Yantramanav-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Yantramanav';
  src: url("../fonts/Yantramanav-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }
 

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html {
  height: 100%;
}

/* Space out content a bit */
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #d9536c url(../images/bg.png);
  color: #fff;
  font-family: 'Yantramanav', 'Helvetica', 'Arial';  
}

/* Meddelande om aktivering av konto */
.status-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1% 5%;
    text-align: center;
    color: #fff;
    background: #000;

    .activate-button {
        display: inline-block;
        border: 0;
        padding: 5px 12px;
        margin-left: 10px;
        color: #fff;
        font-weight: bold;
        background: #d9536c;
    }
}

.container {
  width: 100%;
  max-width: 950px;
  height: 100%;
  margin: 0 auto;
}

h1 {
  font-size: 44px;
  line-height: 40px;
  font-weight: normal;
  text-align: center;
  font-family: 'jackregular';
  margin: 0 0 25px;
  padding: 0;
  
  br {
    display: none;

    @media ("min-width: 375px") {
      display: inline;
    }
  }

  @media ("min-width: 700px") {
    font-size: 50px;
  }
}

p {
  font-size: 18px;
  text-align: center;
  line-height: 27px;
  margin-bottom: 25px;
}

.download, .download:hover, .download:visited, .download:active, .download:focus {
  display: block;
  background: #34131a;
  border: none;
  color: #fff;
  text-decoration: none;
  margin: 0 auto;
  max-width: 250px;
  text-align: center;
  font-family: 'jackregular';
  font-size: 25px;
  padding: 5px 20px 8px;
  outline: none !important;
  border-radius: 5px;
}

.password-form {
    border: 0;
    border-radius: 0;
    height: 50px;
}

.logos {
  margin: 10px 0 25px;
  text-align: center;

  img {
    height: 20px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.pp a {
  color: #fff;
  text-decoration: underline;
}

#pp {  

  color: #000;

  .modal-title {
    float: left;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  h4 {
    font-size: 12px;
    margin: 0;
    font-weight: bold;
  }

  p {
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  ul {
    li {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  
  a {
    color: #000;
    text-decoration: underline;
  }
}

.row {


  height: 100%;
  @media ("min-width: 700px") {
    display: table;
  }

  .col-content, .col-device {

    @media ("min-width: 700px") {
      display: table-cell;
      vertical-align: middle;
    }

    &.col-content {
      padding: 25px;
    }

    &.col-device {

      overflow: hidden;
      height: 300px;
      padding: 0;
      text-align: center;
      
      @media ("min-width: 700px") {
        min-width: 300px;
        max-width: 400px;
        padding-right: 25px;
        vertical-align: middle;
      }      

      img {
        width: 90%;
      }
    }

  }
}
